<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">About Us</h1>

        <p class="subheading font-weight-regular">
          Founded in 2021
          <br />please join our online
          <a href="https://community.vuetifyjs.com" target="_blank"
            >Telegram Chanel</a
          >
        </p>
      </v-col>
      <v-col cols="12">
        <v-carousel
          cycle
          height="400"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet :color="colors[i]" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-h2">{{ slide }} Slide</div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Domain",

  data: () => ({
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    domain: "choose",
  }),
  methods: {
    setLndev() {
      this.domain = "lndev";
    },
    setSatshopper() {
      this.domain = "satshopper";
    },
    setBitcoinblogz() {
      this.domain = "bitcoinblogz";
    },
    cancel() {
      this.domain = "choose";
    },
  },
};
</script>
