<template>
  <v-app>
    <v-app-bar app color="grey darken-2" dark height="150px">
      <v-row class="text-center"
        ><div class="d-flex align-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/icon.png"
            transition="scale-transition"
            width="40"
          />
        </div>
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Worcester Drawing School
          </h1>

          <p class="subheading font-weight-regular">
            Follow your creativity and passion
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>

        <v-container>
          <v-row justify="center">
            <v-col md="8">
              <v-tabs fixed-tabs background-color="grey darken-2" dark>
                <v-tab @click="about"> About </v-tab>
                <v-tab @click="classes"> Classes </v-tab>
                <v-tab @click="contact"> Contact </v-tab>
                <v-tab @click="tutors"> Tutors </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container v-if="content == 'landing'">
        <Landing />
      </v-container>
      <v-container v-if="content == 'about'">
        <About />
      </v-container>
      <v-container v-if="content == 'classes'">
        <Classes />
      </v-container>
      <v-container v-if="content == 'contact'">
        <Contact />
      </v-container>
      <v-container v-if="content == 'tutors'">
        <Tutors />
      </v-container>
      <v-row padding-bottom="150px">
        <v-spacer></v-spacer>
      </v-row>
    </v-main>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} —
          <strong>Worcester Drawing school: All Rights Reserved;</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Landing from "./components/Landing";
import About from "./components/About";
import Classes from "./components/Classes";
import Contact from "./components/Contact";
import Tutors from "./components/Tutors";

export default {
  name: "App",

  components: {
    Landing,
    About,
    Classes,
    Contact,
    Tutors,
  },

  data: () => ({
    content: "landing",
    //
  }),

  methods: {
    landing() {
      this.content = "landing";
    },
    about() {
      this.content = "about";
    },
    classes() {
      this.content = "classes";
    },
    contact() {
      this.content = "contact";
    },
    tutors() {
      this.content = "tutors";
    },
  },
};
</script>
