<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Lead Tutor</div>
              <v-list-item-title class="text-h5 mb-1">
                Jain McKay
              </v-list-item-title>
              <v-list-item-subtitle>a human</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text> Facebook </v-btn>
            <v-btn outlined rounded text> Twitter </v-btn>
            <v-btn outlined rounded text> LinkedIn </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Vps",

  data: () => ({
    //
  }),
};
</script>
